import { DISPOSITION_PAGINATION_PAYLOAD } from 'app/modules/dispositions/constants';

import { formatDisposition } from 'app/shared/utils/disposition';
import { retrieveDispositionsThunk } from 'app/modules/dispositions/sliceDisposition';
import { selectDispositions } from 'app/modules/dispositions/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import {
  U21MultiSelect,
  U21MultiSelectProps,
  U21Select,
  U21SelectProps,
} from 'app/shared/u21-ui/components';

interface U21DispositionSelectSingleProps<TClearable extends boolean>
  extends Omit<U21SelectProps<string, TClearable>, 'options'> {
  multi?: false;
  useTitle?: boolean;
}

interface U21DispositionSelectMultiProps<TClearable extends boolean>
  extends Omit<U21MultiSelectProps<string, TClearable>, 'options'> {
  multi: true;
  useTitle?: boolean;
}

export type U21DispositionSelectProps<TClearable extends boolean> =
  | U21DispositionSelectSingleProps<TClearable>
  | U21DispositionSelectMultiProps<TClearable>;

export const U21DispositionSelect = <TClearable extends boolean>(
  props: U21DispositionSelectProps<TClearable>,
) => {
  const { multi, useTitle, onChange, value, ...rest } = props;
  const dispatch = useDispatch();
  const dispositions = useSelector(selectDispositions);
  const options = useMemo(
    () => [
      ...dispositions.map((i) => ({
        text: formatDisposition(i.title),
        value: useTitle ? i.title : i.id,
      })),
      {
        text: 'Unresolved',
        value: 'UNRESOLVED',
      },
    ],
    [dispositions, useTitle],
  );

  useEffect(() => {
    dispatch(retrieveDispositionsThunk(DISPOSITION_PAGINATION_PAYLOAD));
  }, [dispatch]);

  if (multi) {
    return (
      <U21MultiSelect
        onChange={onChange}
        options={options}
        value={value}
        {...rest}
      />
    );
  }
  return (
    <U21Select
      allowInvalidValues
      onChange={onChange}
      options={options}
      value={value}
      {...rest}
    />
  );
};

import { cloneDeep, get, isNil, isPlainObject } from 'lodash';
import {
  ImmutableTree,
  JsonTree,
  Utils as QbUtils,
} from 'react-awesome-query-builder';

// Models
import { BlacklistType } from 'app/modules/lists/models';
import {
  EditingRuleFiltersModel,
  RuleStatus,
  ScenarioConstants,
} from 'app/modules/rules/models';
import { OrgScenarioConfig } from 'app/modules/orgSettings/models';

// Constants
import {
  MONTHLY_TIME_WINDOW_VALUES,
  TIME_WINDOW_OPTIONS,
  WINDOW_STRIDE_OPTIONS,
  WindowStrideOption,
} from 'app/modules/rules/config/timeWindow';
import {
  MINUTE_IN_MILLISECONDS,
  STRIDE_WITH_NO_WINDOW,
} from 'app/modules/rules/constants';
import { MUSTACHE_MATCHER_REGEX } from 'app/shared/constants';
import {
  EditingSimpleDetectionModel,
  ScenarioParameters,
  ScenarioPayload,
  TimeWindow,
} from 'app/modules/detectionModels/models';
import { getInitialTree } from 'app/modules/rules/components/getInitialTree';
import { getValidationPayload } from 'app/modules/detectionModels/helpers';
import { addDays, addMinutes } from 'date-fns';

export const extractMustacheFields = (content: string): string[] => {
  const results: string[] = [];
  MUSTACHE_MATCHER_REGEX.lastIndex = 0;
  let match = MUSTACHE_MATCHER_REGEX.exec(content);
  while (match != null) {
    results.push(match[0].substring(2, match[0].length - 2));
    match = MUSTACHE_MATCHER_REGEX.exec(content);
  }
  return results;
};

export const determineTimePeriodString = (timeWindow: string | undefined) => {
  const { text } = getTimeWindowTextFromValue(timeWindow);
  if (timeWindow === '*') {
    return 'from all time';
  } else if (timeWindow === STRIDE_WITH_NO_WINDOW || !timeWindow) {
    return 'from the specified periods of time';
  }
  return `from the preceding ${text} period`;
};

export function isValidTree(
  queryTree: ImmutableTree,
  achSubtypes: string[] | undefined = undefined,
): boolean {
  return allFieldsValid(QbUtils.getTree(queryTree), achSubtypes);
}

function allFieldsValid(
  queryTree: JsonTree,
  achSubtypes: string[] | undefined = undefined,
): boolean {
  const emptyFields: any[] = [];

  if (queryTree.children1) {
    const itemValues = Object.values(queryTree.children1);
    for (const condition of itemValues) {
      const fieldName: string = get(condition, 'properties.field') || '';
      const fieldOperator: string = get(condition, 'properties.operator') || '';
      const fieldValue: any[] = get(condition, 'properties.value') || [];
      const isCheckingForEmptiness: boolean = [
        'is_empty',
        'is_not_empty',
        'is_null',
        'is_not_null',
      ].includes(fieldOperator);
      const isValid: boolean =
        isCheckingForEmptiness ||
        (fieldValue.length !== 0 && !hasEmptyValue(fieldValue));
      const hasFieldName: boolean = fieldName.length > 0;
      if (
        hasFieldName &&
        isValid &&
        achSubtypes &&
        fieldName.endsWith('u21_ach_risk_score') &&
        (!isAchFieldValid(fieldValue) ||
          !hasAchTxnType(itemValues, achSubtypes))
      ) {
        return false;
      }
      if (
        condition?.type === 'group' &&
        !allFieldsValid(condition, achSubtypes)
      ) {
        return false;
      }

      if (hasFieldName && !isValid) {
        emptyFields.push({
          fieldName,
          fieldValue,
        });
      }
    }
  }
  return emptyFields.length === 0;
}

const hasAchTxnType = (itemValues, achSubtypes: string[]): boolean => {
  // user must first select ach as the type of transactions to flag in a group of conditions
  return itemValues.some(
    (itemValue) =>
      itemValue?.properties?.field === 'txn_event.internal_txn_type' &&
      itemValue?.properties?.operator === 'multiselect_equals' &&
      itemValue?.properties?.value.length === 1 &&
      itemValue?.properties?.value[0] !== undefined &&
      itemValue?.properties?.value[0].every((value) =>
        achSubtypes.includes(value),
      ),
  );
};

const isAchFieldValid = (fieldValue): boolean =>
  fieldValue.every((scoreVal) => scoreVal >= 0 && scoreVal <= 100);

function hasEmptyValue(fieldValue: string[] | object) {
  if (Array.isArray(fieldValue)) {
    return fieldValue.some((currValue) => {
      if (Array.isArray(currValue)) {
        return hasEmptyValue(currValue);
      }

      if (typeof currValue === 'object') {
        return recursivelyCheckIfAnyValueIsEmpty(currValue);
      }

      return isNil(currValue);
    });
  }

  if (typeof fieldValue === 'object') {
    return recursivelyCheckIfAnyValueIsEmpty(fieldValue);
  }

  return isNil(fieldValue);
}

function recursivelyCheckIfAnyValueIsEmpty(currObject: object) {
  if (isNil(currObject)) {
    return true;
  }

  return Object.keys(currObject).some((key) => {
    if (typeof currObject[key] === 'object') {
      return recursivelyCheckIfAnyValueIsEmpty(currObject[key]);
    }

    if (Array.isArray(currObject[key])) {
      return hasEmptyValue(currObject[key]);
    }

    return isNil(currObject[key]);
  });
}

export const windowCountWithDates = (
  execStartDatetime: Date,
  windowStride: string,
  execEndDatetime?: Date,
) => {
  const startTimeInMilliseconds = execStartDatetime.getTime();
  const endTimeInMilliseconds =
    execEndDatetime && execEndDatetime.getTime() < Date.now()
      ? execEndDatetime.getTime()
      : Date.now();
  const durationInMinutes =
    (endTimeInMilliseconds - startTimeInMilliseconds) / MINUTE_IN_MILLISECONDS;

  const windowStrideInMinutes = windowStrideStrToMinutes(windowStride);
  return Math.floor(durationInMinutes / windowStrideInMinutes) + 1;
};

export const windowCount = (
  execStartDatetime: string,
  execEndDatetime: string,
  windowStride: string,
) => {
  const startTimeInMilliseconds = new Date(execStartDatetime).getTime();
  const endTimeInMilliseconds = execEndDatetime
    ? new Date(execEndDatetime).getTime()
    : Date.now();
  const durationInMinutes =
    (endTimeInMilliseconds - startTimeInMilliseconds) / MINUTE_IN_MILLISECONDS;

  const windowStrideInMinutes = windowStrideStrToMinutes(windowStride);
  return Math.floor(durationInMinutes / windowStrideInMinutes) + 1;
};

export const calculateWindowEnd = (
  execStartDatetime: string,
  windowSize: string | undefined,
): Date => {
  return addMinutes(
    new Date(execStartDatetime),
    getTimeWindowTextFromValue(windowSize).minutes,
  );
};

export const addDaysToDate = (date: Date, daysToAdd: number): Date => {
  return addDays(date, daysToAdd);
};

export const timeWindowStrIsMonthly = (timeWindow: string): boolean => {
  const found = TIME_WINDOW_OPTIONS.find((e) => e.value === timeWindow);
  if (!found || !found.value) {
    return false;
  }
  return MONTHLY_TIME_WINDOW_VALUES.includes(found.value);
};

export const windowStrideStrToMinutes = (windowStride: string): number => {
  const found = WINDOW_STRIDE_OPTIONS.find((e) => e.value === windowStride);
  if (!found) {
    return 0;
  }
  return found.minutes;
};

export const getDefaultStrideForTimeWindow = (timeWindow: string): string => {
  const found = TIME_WINDOW_OPTIONS.find((e) => e.value === timeWindow);
  if (!found) {
    return TIME_WINDOW_OPTIONS[0].defaultstride;
  }
  return found.defaultstride;
};

export const getTimeWindowTextFromValue = (
  timeWindowValue: string | undefined,
): TimeWindow => {
  const returnWindow = {
    key: '',
    value: '',
    text: '',
    minutes: 0,
    defaultstride: '',
  };

  if (timeWindowValue === STRIDE_WITH_NO_WINDOW) {
    returnWindow.text = STRIDE_WITH_NO_WINDOW;
    return returnWindow;
  }

  const found = TIME_WINDOW_OPTIONS.find((e) => e.value === timeWindowValue);
  if (!found) {
    return returnWindow;
  }
  return found;
};

// so. this is a bit of a hack for world remit
// currently multiple occurrences is capable of running any rule, regardless of status
// we're doing this to satisfy this conversation https://unit21chat.slack.com/archives/C01UFHHPHRP/p1634310833355800
export const constructRulesSelectorStatuses = (
  scenarioName: string | null,
): RuleStatus[] => {
  const statuses: RuleStatus[] = ['ACTIVE', 'VALIDATION'];

  if (scenarioName === 'multiple_occurrences') {
    statuses.push('ARCHIVED');
  }

  return statuses;
};

export const getScenarioDefaultValues = (
  baseScenarioConfig: any,
  orgScenarioConfig: OrgScenarioConfig,
): ScenarioParameters => {
  const parameterOptions = baseScenarioConfig.parameter_options;
  const orgScenarioConfigValues = orgScenarioConfig.values || {};
  const { content } = baseScenarioConfig;
  const defaultValues: ScenarioParameters = {};

  MUSTACHE_MATCHER_REGEX.lastIndex = 0;
  let match = MUSTACHE_MATCHER_REGEX.exec(content);
  while (match != null) {
    const key = match[0].substring(2, match[0].length - 2);
    switch (key) {
      case ScenarioConstants.TIME_WINDOW: {
        let timeWindowStr;
        if (
          key in parameterOptions &&
          'default_value' in parameterOptions[key]
        ) {
          timeWindowStr = parameterOptions[key].default_value;
        } else {
          timeWindowStr =
            TIME_WINDOW_OPTIONS[TIME_WINDOW_OPTIONS.length - 1].value;
        }
        defaultValues[ScenarioConstants.TIME_WINDOW] = timeWindowStr;
        defaultValues.$window_stride =
          getDefaultStrideForTimeWindow(timeWindowStr);
        break;
      }
      case ScenarioConstants.NUMERIC_COMPARISON:
        break; // No default values set for $numeric_comparison
      default:
        if (
          key in parameterOptions &&
          'default_value' in parameterOptions[key] &&
          parameterOptions[key].field_type === 'multi_select'
        ) {
          defaultValues[key] = parameterOptions[key].default_value;
        } else if (
          key in parameterOptions &&
          'default_value' in parameterOptions[key]
        ) {
          const defaultValue = Array.isArray(
            parameterOptions[key].default_value,
          )
            ? parameterOptions[key].default_value
            : parameterOptions[key].default_value.toString();
          defaultValues[key] = defaultValue;
        } else if (
          key in parameterOptions &&
          'org_values' in parameterOptions[key] &&
          parameterOptions[key].org_values in orgScenarioConfigValues
        ) {
          // eslint-disable-next-line prefer-destructuring
          defaultValues[key] =
            orgScenarioConfigValues[parameterOptions[key].org_values][0];
        } else if (
          `${baseScenarioConfig.name}:${key}` in orgScenarioConfigValues
        ) {
          // eslint-disable-next-line prefer-destructuring
          defaultValues[key] =
            orgScenarioConfigValues[`${baseScenarioConfig.name}:${key}`][0];
        } else if (
          key in parameterOptions &&
          parameterOptions[key].field_type === 'dropdown' &&
          parameterOptions[key].values &&
          parameterOptions[key].values.length > 0
        ) {
          // eslint-disable-next-line prefer-destructuring
          defaultValues[key] = parameterOptions[key].values[0];
        } else if (
          key in parameterOptions &&
          parameterOptions[key].field_type === 'dropdown' &&
          parameterOptions[key].key_values &&
          parameterOptions[key].key_values.length > 0
        ) {
          // eslint-disable-next-line prefer-destructuring
          defaultValues[key] = parameterOptions[key].key_values[0];
          if (
            isPlainObject(defaultValues[key]) &&
            Object.prototype.hasOwnProperty.call(defaultValues[key], 'value')
          ) {
            // key_values can be an array of objects with 'key', 'text' and 'value', use the 'value' property
            defaultValues[key] = defaultValues[key].value;
          }
        }
    }
    match = MUSTACHE_MATCHER_REGEX.exec(content);
  }
  return defaultValues;
};

export const changeScenarioParameter = (
  scenario: ScenarioPayload,
  key: string,
  val: any,
) => {
  const selectedScenario = cloneDeep(scenario);

  if (selectedScenario.parameters[key] === 'between' && val !== 'between') {
    delete selectedScenario.parameters[
      `${key.replace('-comparator', '')}-extra_value`
    ];
  }

  return {
    ...selectedScenario,
    parameters: {
      ...selectedScenario.parameters,
      [key]: val,
    },
    embeddedFilters: {
      ...selectedScenario.embeddedFilters,
      [key]: getEmptyFilters(),
    },
  };
};
export const changeScenarioParameterOnly = (
  scenario: ScenarioPayload,
  key: string,
  val: any,
): ScenarioPayload => {
  const selectedScenario = cloneDeep(scenario);

  return {
    ...selectedScenario,
    parameters: {
      ...selectedScenario.parameters,
      [key]: val,
    },
  };
};

export const getEmptyFilters = (): EditingRuleFiltersModel => ({
  raw_sql: '',
  query_tree: QbUtils.loadTree(getInitialTree()),
  inclusion_tags: [],
  exclusion_tags: [],
  inclusion_tag_names: [],
  exclusion_tag_names: [],
  aggregate_query_tree: {},
});

export const ONLY_USERS_FIELD_KEY = 'ONLYUSERKEYS';

export function getFieldNamesFromKey(
  key: string,
  blacklistType: BlacklistType | undefined,
): string[] {
  // key looks like `txn_events_1`, `entity.id`, `instrument.id_2`,
  // returned fieldName must exist on QueryBuilderConfig.fields
  const tableAndFieldName = key.replace(/_\d+$/, '');
  const entityFields = ['business', 'entity', 'user'];
  const userFields = ['entity', 'user'];

  // Try to obtain the main field if we have a field.property => entity.id would apply to entity
  // TODO do we really want to do this? Maybe filter subfields from qbConfig
  const [tableName, fieldName] = tableAndFieldName.split('.');
  let filteredTable: string[];

  switch (tableName) {
    case 'txn_events':
      filteredTable = ['txn_event'];
      break;
    case 'entity':
      if (fieldName === ONLY_USERS_FIELD_KEY) {
        filteredTable = userFields;
      } else {
        filteredTable = entityFields;
      }
      break;
    case 'instrument':
      filteredTable = ['txn_instrument'];
      break;
    case 'transaction':
      filteredTable = ['txn_event'];
      break;
    default:
      filteredTable = [tableName];
  }

  if (fieldName && filteredTable.includes('txn_event')) {
    filteredTable = getTableFromFieldName(fieldName) || filteredTable;
  }

  return filterByBlacklistType(filteredTable);

  function getTableFromFieldName(name: string): string[] | null {
    if (name.includes('entity')) return entityFields;
    if (name.includes('instrument')) return ['txn_instrument'];
    return null;
  }

  function filterByBlacklistType(filteredTableX: string[]): string[] {
    if (
      ['BUSINESS', 'USER'].includes(String(blacklistType)) &&
      filteredTableX === entityFields
    ) {
      return blacklistType ? [blacklistType.toLowerCase()] : [];
    }
    return filteredTableX;
  }
}

/**
 * This doesn't have to be exact. It's just used to get a rough range.
 */
const BREAKDOWN_TO_MINUTES = {
  min: 1,
  h: 60,
  d: 24 * 60,
  w: 24 * 60 * 7,
  m: 24 * 60 * 31,
  y: 24 * 60 * 365,
};

export const convertCustomTimeWindowToMinutes = (
  timeWindow: string,
): number => {
  const [, timeNumStr, timeUnit] = timeWindow.split(/(\d+)/); // this actually returns ["", "17", "w"]
  const timeNumber = parseInt(timeNumStr, 10);
  if (isNaN(timeNumber)) {
    throw new Error(`Invalid time window: ${timeWindow}`);
  }
  return BREAKDOWN_TO_MINUTES[timeUnit] * timeNumber;
};

// timeWindow -> (exclusiveOptionLimit, inclusiveOptionLimit]
// timeWindow is an earlier selected time from constant TIME_WINDOW_OPTIONS
// where "exclusiveOptionLimit" is an option that is just greater than 10 minutes and an arbitruary buffer
// and "inclusiveOptionLimit" is an option less than or equal to the initially selected time window
export const getWindowStrideOptions = (
  timeWindow: string,
  minMinutes: number = 10,
): WindowStrideOption[] => {
  const timeWindowInMinutes = convertCustomTimeWindowToMinutes(timeWindow);
  return WINDOW_STRIDE_OPTIONS.filter((opt) => {
    const isTheOptionLongerThan10Minutes = opt.minutes >= minMinutes;
    const isMonthlyOrNotRequired =
      timeWindowStrIsMonthly(timeWindow) ||
      opt.monthly_window_required === undefined;

    const exclusiveOptionLimit =
      (opt.minutes > timeWindowInMinutes / 30 &&
        isTheOptionLongerThan10Minutes) ||
      isTheOptionLongerThan10Minutes;

    const inclusiveOptionLimit = opt.minutes <= timeWindowInMinutes;

    return (
      exclusiveOptionLimit && inclusiveOptionLimit && isMonthlyOrNotRequired
    );
  });
};

export const getInitialSimpleEditingModel = (
  parentModel: EditingSimpleDetectionModel,
  dataMigrationLag: number,
): EditingSimpleDetectionModel => {
  const newScenarioPayload = cloneDeep(parentModel);

  newScenarioPayload.validationPayload = getValidationPayload(
    newScenarioPayload.metadata,
    newScenarioPayload.validationPayload,
    dataMigrationLag,
  );

  return newScenarioPayload;
};

import { OFFSET_DEFAULT } from 'app/shared/constants';

import { formatInstrumentOptions } from 'app/modules/search/helpers';
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';
import { useSelector } from 'react-redux';
import { useEffect, useMemo, useRef } from 'react';

import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import { InstrumentSearchRequest } from 'app/modules/search/models';
import {
  searchInstrumentsV1,
  useSearchInstrumentsV1,
} from 'app/modules/search/queries/useSearchInstruments';
import { useQueryClient } from '@tanstack/react-query';
import { SEARCH_QUERY_KEYS } from 'app/modules/search/queries/keys';
import {
  searchInstruments,
  useInstrumentSearch,
} from 'app/modules/instruments/queries/useInstrumentSearch';
import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import { InstrumentSearchOptions } from 'app/modules/search/enum';
import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';

interface InstrumentFilterDisplayProps {
  externalIds: string[];
  label: string;
}

export const InstrumentFilterDisplay = ({
  externalIds,
  label,
}: InstrumentFilterDisplayProps) => {
  const queryClient = useQueryClient();
  const hasReadInstrumentsPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);

  const isV2SearchUsed = useMemo(
    () => Boolean(isSearchV2Enabled),
    [isSearchV2Enabled],
  );

  const { instruments: instrumentV1 } = useSearchInstrumentsV1(
    undefined,
    !isV2SearchUsed,
  );
  const { instruments: instrumentV2 } = useInstrumentSearch(
    undefined,
    isV2SearchUsed,
  );

  const instruments = useMemo(
    () => (isV2SearchUsed ? instrumentV2 : instrumentV1),
    [instrumentV1, instrumentV2, isV2SearchUsed],
  );

  const instrumentOptionsMap = useMemo(
    () => formatInstrumentOptions(instruments),
    [instruments],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [externalIds]);

  useEffect(() => {
    if (hasReadInstrumentsPermissions) {
      const missingValues = externalIds.filter(
        (i) => !instrumentOptionsMap.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;

        if (isV2SearchUsed) {
          const payload = {
            search_field: InstrumentSearchOptions.EXTERNAL_ID,
            phrases: missingValues.map(String),
            exact_match: true,
            offset: OFFSET_DEFAULT,
            limit: missingValues.length,
            all_phrases_must_match: false,
          };

          queryClient.prefetchQuery({
            queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentSearch(payload),
            queryFn: () => searchInstruments(payload),
          });
        } else {
          const searchPayload: InstrumentSearchRequest = {
            phrase: '',
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            type: 'external_id',
            external_ids: missingValues,
          };

          queryClient.prefetchQuery({
            queryKey: SEARCH_QUERY_KEYS.searchInstrumentsV1(searchPayload),
            queryFn: () => searchInstrumentsV1(searchPayload),
          });
        }
      }
    }
  }, [
    externalIds,
    hasReadInstrumentsPermissions,
    instrumentOptionsMap,
    isV2SearchUsed,
    queryClient,
  ]);

  const values = useMemo(
    () => externalIds.map((i) => instrumentOptionsMap[i]?.text || i),
    [instrumentOptionsMap, externalIds],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ACTION_EVENT_QUERY_KEYS } from 'app/modules/actionEvents/queries/keys';
import { ActionEventSearchPayload } from 'app/modules/search/payload';
import {
  ActionEventSearchV2Response,
  ActionEventSource,
} from 'app/modules/search/response';
import { post } from 'app/shared/utils/fetchr';
import { unionBy } from 'lodash';
import { useMemo } from 'react';

const getActionEventSources = (
  data: ActionEventSearchV2Response,
): ActionEventSource[] => data.hits.map((hit) => hit.source);

export const searchActionEvent = (payload?: ActionEventSearchPayload) =>
  post<ActionEventSearchV2Response>('/action_events/search/v2', payload);

export const useActionEventSearch = (
  payload?: ActionEventSearchPayload,
  enabled?: boolean,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<
    ActionEventSearchV2Response,
    Error,
    ActionEventSource[]
  >({
    queryFn: () => searchActionEvent(payload),
    queryKey: ACTION_EVENT_QUERY_KEYS.getActionEventSearch(payload),
    enabled: Boolean(enabled && !!payload),
    staleTime: 5 * 60 * 1000,
    select: (data) => getActionEventSources(data),
  });

  const cachedQueryData =
    queryClient.getQueriesData<ActionEventSearchV2Response>({
      queryKey: ACTION_EVENT_QUERY_KEYS.getActionEventSearch(),
    });

  const actionEvents = useMemo(
    () =>
      cachedQueryData.reduce<ActionEventSource[]>((acc, i) => {
        const [, queryData] = i;
        if (queryData) {
          return unionBy(getActionEventSources(queryData), acc, 'external_id');
        }
        return acc;
      }, []),

    [cachedQueryData],
  );

  return {
    ...query,
    actionEvents,
  };
};

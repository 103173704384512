import { OFFSET_DEFAULT } from 'app/shared/constants';
import { entitiesSearch } from 'app/modules/search/actions';
import { formatEntityOptions, ValueField } from 'app/modules/search/helpers';
import { selectHasReadEntitiesPermissions } from 'app/modules/session/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FilterMoreDisplay } from 'app/modules/filters/components/FilterMoreDisplay';
import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import { useGetEntities } from 'app/modules/entitiesRefresh/queries/useGetEntities';
import { DEFAULT_OFFSET } from 'app/shared/pagination/constants';
import { GetEntitiesPayload } from 'app/modules/entitiesRefresh/requests';

interface Props {
  ids: string[];
  label: string;
  valueField?: ValueField;
}

export const EntityFilterDisplay = (props: Props) => {
  const { ids, label, valueField } = props;
  const dispatch = useDispatch();

  const [newPayload, setNewPayload] = useState<GetEntitiesPayload>({
    offset: DEFAULT_OFFSET,
    limit: ids.length ?? 0,
    entity_ids: valueField === ValueField.ID ? ids : undefined,
    entity_external_ids:
      valueField === ValueField.EXTERNAL_ID ? ids : undefined,
  });

  // using search/selectors caused an import cycle
  const v1Entities = useSelector(
    (state: RootState) => state.search.entities.data,
  );
  const hasReadEntitiesPermissions = useSelector(
    selectHasReadEntitiesPermissions,
  );
  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);

  const { data: entities } = useGetEntities(newPayload, {
    enabled: isSearchV2Enabled,
  });

  const data = useMemo(
    () => (isSearchV2Enabled ? (entities?.entities ?? []) : v1Entities),
    [entities, isSearchV2Enabled, v1Entities],
  );

  // Map of external IDs to options
  const entityOptionsMap = useMemo(
    () =>
      formatEntityOptions(data, undefined, valueField).reduce(
        (acc, i) => ({
          ...acc,
          [i.value]: i,
        }),
        {},
      ),
    [data, valueField],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [ids]);

  useEffect(() => {
    if (hasReadEntitiesPermissions) {
      const missingValues = ids.filter((i) => !entityOptionsMap[i]);
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;

        if (isSearchV2Enabled) {
          setNewPayload({
            offset: DEFAULT_OFFSET,
            limit: missingValues.length ?? 0,
            entity_ids:
              valueField === ValueField.ID ? missingValues : undefined,
            entity_external_ids:
              valueField === ValueField.EXTERNAL_ID ? missingValues : undefined,
          });
        } else if (valueField === ValueField.ID) {
          dispatch(
            entitiesSearch({
              ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
              type: 'unit21_id',
            }),
          );
        } else {
          dispatch(
            entitiesSearch({
              external_ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
              type: 'external_id',
            }),
          );
        }
      }
    }
  }, [
    dispatch,
    entityOptionsMap,
    hasReadEntitiesPermissions,
    ids,
    isSearchV2Enabled,
    valueField,
  ]);

  const values = useMemo(
    () => ids.map((i) => entityOptionsMap[i]?.text || i),
    [entityOptionsMap, ids],
  );

  return (
    <>
      <b>{label}</b> is one of <FilterMoreDisplay values={values} />
    </>
  );
};

import {
  FC,
  HTMLProps,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ENTITY_SEARCH_INDEX_MAP,
  ENTITY_SEARCH_TYPE_OPTIONS,
  OLD_TO_NEW_SEARCH_TYPE_MAP,
} from 'app/modules/search/constants';
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';
import permissions from 'app/shared/utils/permissions';
import styled from 'styled-components';

import { entitiesSearch } from 'app/modules/search/actions';
import { formatEntityOptions, ValueField } from 'app/modules/search/helpers';
import {
  selectEntitiesSearchResults,
  selectEntitiesSearchLoading,
} from 'app/modules/search/selectors';
import { selectHasPermissionsFactory } from 'app/modules/session/selectors';
import { useSelector, useDispatch } from 'react-redux';

import { IconBuilding, IconSearch, IconUser } from '@u21/tabler-icons';
import {
  U21InputGroup,
  U21Select,
  U21SelectSearch,
  U21SelectProps,
  U21SelectSearchMultiProps,
} from 'app/shared/u21-ui/components';
import { useLocalStorageState } from 'app/shared/hooks/useLocalStorage';
import { LocalStorageKeys } from 'app/shared/constants/localStorage';
import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import { EntitySearchPayload } from 'app/modules/search/payload';
import {
  searchEntity,
  useEntitySearch,
} from 'app/modules/entitiesRefresh/queries/useEntitySearch';
import { useQueryClient } from '@tanstack/react-query';
import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';
import { EntitySearchOptions } from 'app/modules/search/enum';

export interface U21EntitySearchProps
  extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'onChange' | 'value'> {
  disabled?: boolean;
  error?: boolean;
  clearable?: boolean;
  onChange: (value: (number | string)[], e: SyntheticEvent) => void;
  value?: (number | string)[];
  valueField?: ValueField;
  selectProps?: Omit<
    U21SelectProps<string, false>,
    'disabled' | 'error' | 'onChange' | 'options' | 'ref' | 'as'
  >;
  searchProps?: Omit<
    U21SelectSearchMultiProps<number>,
    | 'disabled'
    | 'error'
    | 'clearable'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'ref'
    | 'value'
    | 'multi'
    | 'as'
  >;
}

const selectHasReadEntitiesPermissions = selectHasPermissionsFactory(
  permissions.readEntities,
);

export const U21EntitySearch: FC<U21EntitySearchProps> = ({
  disabled,
  error,
  clearable = true,
  onChange,
  value = [],
  valueField = ValueField.EXTERNAL_ID,
  selectProps = {},
  searchProps = {},
  ...rest
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [newPayload, setNewPayload] = useState<EntitySearchPayload>();

  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);
  const { data: v1Data } = useSelector(selectEntitiesSearchResults);
  const v1Loading = useSelector(selectEntitiesSearchLoading);
  const hasReadEntitiesPermissions = useSelector(
    selectHasReadEntitiesPermissions,
  );

  const [entityIdExactMatch] = useLocalStorageState<boolean>(
    LocalStorageKeys.SEARCH_ENTITY_ID_EXACT_MATCH,
    true,
  );

  const [type, setType] = useState(
    ENTITY_SEARCH_TYPE_OPTIONS[
      entityIdExactMatch
        ? ENTITY_SEARCH_INDEX_MAP['Exact Entity ID']
        : ENTITY_SEARCH_INDEX_MAP['Entity ID']
    ].value,
  );

  const isV2SearchUsed = useMemo(
    () => Boolean(isSearchV2Enabled && OLD_TO_NEW_SEARCH_TYPE_MAP[type]),
    [isSearchV2Enabled, type],
  );

  const { entities, isFetching } = useEntitySearch(newPayload, isV2SearchUsed);

  const data = useMemo(
    () => (isV2SearchUsed ? entities : v1Data),
    [entities, isV2SearchUsed, v1Data],
  );

  const loading = useMemo(
    () => (isV2SearchUsed ? isFetching : v1Loading),
    [isFetching, isV2SearchUsed, v1Loading],
  );

  useEffect(() => {
    setType((prevType) =>
      prevType ===
        ENTITY_SEARCH_TYPE_OPTIONS[ENTITY_SEARCH_INDEX_MAP['Entity ID']]
          .value ||
      prevType ===
        ENTITY_SEARCH_TYPE_OPTIONS[ENTITY_SEARCH_INDEX_MAP['Exact Entity ID']]
          .value
        ? ENTITY_SEARCH_TYPE_OPTIONS[
            entityIdExactMatch
              ? ENTITY_SEARCH_INDEX_MAP['Exact Entity ID']
              : ENTITY_SEARCH_INDEX_MAP['Entity ID']
          ].value
        : prevType,
    );
  }, [entityIdExactMatch]);

  const options = useMemo(
    () => formatEntityOptions(data, type, valueField),
    [data, type, valueField],
  );

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadEntitiesPermissions) {
      const missingValues = value.filter(
        (i) => !options.find((j) => j.value === i),
      );

      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;
        if (isV2SearchUsed && OLD_TO_NEW_SEARCH_TYPE_MAP[type]) {
          const payload = {
            search_field: EntitySearchOptions.EXTERNAL_ID,
            phrases: missingValues.map(String),
            exact_match: true,
            offset: OFFSET_DEFAULT,
            limit: missingValues.length,
            all_phrases_must_match: false,
            hydrate_unit21_id: valueField === ValueField.ID,
          };

          queryClient.prefetchQuery({
            queryKey: ENTITY_QUERY_KEYS.getEntitySearch(payload),
            queryFn: () => searchEntity(payload),
          });

          setNewPayload(payload);
        } else if (valueField === ValueField.ID) {
          dispatch(
            entitiesSearch({
              ids: missingValues,
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
              type: 'unit21_id',
            }),
          );
        } else {
          dispatch(
            entitiesSearch({
              external_ids: missingValues.map(String),
              limit: missingValues.length,
              offset: OFFSET_DEFAULT,
              phrase: '',
              type: 'external_id',
            }),
          );
        }
      }
    }
  }, [
    dispatch,
    hasReadEntitiesPermissions,
    isV2SearchUsed,
    options,
    queryClient,
    type,
    value,
    valueField,
  ]);

  const onSearch = useCallback(
    (query) => {
      if (query) {
        if (isV2SearchUsed) {
          setNewPayload({
            search_field: OLD_TO_NEW_SEARCH_TYPE_MAP[type],
            phrases: query ? [query] : [],
            exact_match: type === 'exact_external_id',
            offset: OFFSET_DEFAULT,
            limit: INFINITE_SCROLL_LIMIT,
            all_phrases_must_match: false,
            hydrate_unit21_id: valueField === ValueField.ID,
          });
        } else {
          dispatch(
            entitiesSearch({
              limit: INFINITE_SCROLL_LIMIT,
              offset: OFFSET_DEFAULT,
              phrase: query || '',
              type,
              ...(type === 'exact_external_id'
                ? {
                    type: 'external_id',
                    external_ids: (query || '')
                      .split(',')
                      .map((id) => id.trim()),
                  }
                : {}),
            }),
          );
        }
      }
    },
    [dispatch, isV2SearchUsed, type, valueField],
  );

  if (!hasReadEntitiesPermissions) {
    return null;
  }

  return (
    <U21InputGroup disabled={disabled} error={error}>
      <StyledU21Select
        clearable={false}
        disabled={disabled}
        error={error}
        label="Search Entity"
        onChange={(val: string) => setType(val)}
        options={ENTITY_SEARCH_TYPE_OPTIONS}
        searchable={false}
        startIcon={type === 'name' ? <IconBuilding /> : <IconUser />}
        value={type}
        {...selectProps}
      />
      <StyledU21SelectSearch
        disabled={disabled}
        error={error}
        clearable={clearable}
        limitTags={1}
        loading={loading}
        multi
        onChange={onChange}
        options={options}
        onSearch={onSearch}
        placeholder="Any"
        startIcon={<IconSearch />}
        value={value}
        {...rest}
        {...searchProps}
      />
    </U21InputGroup>
  );
};

const StyledU21Select = styled(U21Select)`
  min-width: 200px;
  width: 200px;
`;

const StyledU21SelectSearch = styled(U21SelectSearch)`
  min-width: 300px;
`;

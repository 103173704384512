// TODO add NoComparisonOperators (e.g. "is defined", available through NEQ NULL)

export enum SimpleComparisonOperators {
  EQ = 'EQ',
  NEQ = 'NEQ',
  GT = 'GT',
  LT = 'LT',
  GE = 'GE',
  LE = 'LE',
}

export enum ArrayComparisonOperators {
  IN = 'IN',
  NOT_IN = 'NOT_IN',
}

export type CustomDataFilterOperatorType =
  | SimpleComparisonOperators
  | ArrayComparisonOperators;

interface GenericCustomFilter {
  key: string;
  operator: CustomDataFilterOperatorType;
  value: any;
  // We can't rely on the key having dot notation, we get this from org_enums
  nested: boolean;
}

export interface SimpleComparisonFilter extends GenericCustomFilter {
  value: string | number | null;
  operator: SimpleComparisonOperators.EQ | SimpleComparisonOperators.NEQ;
}

export interface NumericComparisonFilter extends GenericCustomFilter {
  value: string | number;
  operator:
    | SimpleComparisonOperators.GT
    | SimpleComparisonOperators.LT
    | SimpleComparisonOperators.GE
    | SimpleComparisonOperators.LE;
}

export interface ContainsComparisonFilter extends GenericCustomFilter {
  value: (string | number)[];
  operator: ArrayComparisonOperators.IN | ArrayComparisonOperators.NOT_IN;
}

export type CustomDataFilter =
  | SimpleComparisonFilter
  | NumericComparisonFilter
  | ContainsComparisonFilter;

export interface CustomDataFilters {
  // Top keys are the objects that contain the custom data
  entity?: CustomDataFilter[];
  event?: CustomDataFilter[];
  txn_instrument?: CustomDataFilter[];
}

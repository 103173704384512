import { GetActionEventsPayload } from 'app/modules/actionEvents/requests';
import { ActionEventSearchPayload } from 'app/modules/search/payload';

const BASE = ['action-events'];

export const ACTION_EVENT_QUERY_KEYS = {
  getActionEvents: (endpoint: string, payload: GetActionEventsPayload) =>
    [...BASE, endpoint, payload] as const,
  getActionEventDetail: (id: number) => [...BASE, id] as const,
  getActionEventSearch: (payload?: ActionEventSearchPayload) =>
    payload
      ? ([...BASE, 'search', payload] as const)
      : ([...BASE, 'search'] as const),
};

import { AnalyticsEvents, trackEvent } from 'app/shared/u21-ui/analytics';
import { ColorSchema } from 'vendor/material-minimal/palette';

import { consoleError } from 'app/shared/utils/console';
import { createPortal } from 'react-dom';
import {
  createSelectOptionItemProps,
  createU21FilterOptions,
  flattenOption,
  generatePopperStyleProp,
  sortByMatchedness,
} from 'app/shared/u21-ui/components/input/select/utils';
import {
  ForwardedRef,
  forwardRef,
  HTMLProps,
  ReactElement,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getDOMProps } from 'app/shared/utils/react';
import { isEqual } from 'lodash';
import emptyFn from 'app/shared/utils/empty-fn';

import { AdornmentButton } from 'app/shared/u21-ui/components/input/text-field/AdornmentButton';
import {
  AutocompleteTextField,
  Menu,
  StyledBackdrop,
} from 'app/shared/u21-ui/components/input/select/styles';
import { Autocomplete, BackdropProps, InputAdornment } from '@mui/material';
import {
  ClearButton,
  Loading,
} from 'app/shared/u21-ui/components/input/text-field/styles';
import { IconChevronDown, IconChevronUp } from '@u21/tabler-icons';
import { SelectOptionItem } from 'app/shared/u21-ui/components/input/select/SelectOptionItem';
import {
  U21MenuItem,
  U21NestedMenuProps,
} from 'app/shared/u21-ui/components/display/U21MenuItem';
import { U21TextFieldProps } from 'app/shared/u21-ui/components/input/text-field/U21TextFieldInputProps';
import { U21TooltipProps } from 'app/shared/u21-ui/components/display/U21Tooltip';

export type U21SelectValue = JSONValue;

interface BaseSelectOptionProps {
  color?: ColorSchema;
  description?: string;
  disabled?: boolean;
  icon?: ReactElement;
  tooltip?: U21TooltipProps['tooltip'];
  text: string | number;
  onClick?: (e: SyntheticEvent) => void;
  checkbox?: boolean;
  optionType?: undefined;
  required?: boolean;
}

export interface U21UnnestedSelectOption<
  TValue extends U21SelectValue = U21SelectValue,
> extends BaseSelectOptionProps {
  children?: undefined;
  value: TValue;
}

export interface U21NestedSelectOption<
  TValue extends U21SelectValue = U21SelectValue,
> extends BaseSelectOptionProps {
  children: (U21NestedSelectOption<TValue> | U21UnnestedSelectOption<TValue>)[];
  value?: undefined;
}

export type U21SelectOptionProps<
  TValue extends U21SelectValue = U21SelectValue,
> = U21NestedSelectOption<TValue> | U21UnnestedSelectOption<TValue>;

export type U21FlattenedSelectOptionProps<TValue extends U21SelectValue> =
  DistributiveOmit<U21SelectOptionProps<TValue>, 'children'> & {
    children?: U21FlattenedSelectOptionProps<TValue>[];
    isNestedOption?: boolean;
    searchTerms: string[];
    values: TValue[];
  };

export interface U21SelectNestedMenuProps extends U21NestedMenuProps {
  optionType: 'menuItem';
  value?: undefined; // kept around so all option props have a value prop
}

export interface U21SelectProps<
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
> extends Omit<HTMLProps<HTMLDivElement>, 'onChange' | 'readOnly' | 'value'> {
  allowInvalidValues?: boolean;
  autoFocus?: boolean;
  backdrop?: boolean;
  backdropProps?: Omit<BackdropProps, 'open'>;
  clearable?: TClearable;
  disabled?: boolean;
  error?: boolean;
  endIcon?: ReactNode;
  expand?: boolean;
  sortByMatchednessDisabled?: boolean;
  filterOptions?: typeof createU21FilterOptions<TValue>;
  inputProps?: U21TextFieldProps['inputProps'];
  inputValue?: string;
  label?: string;
  loading?: boolean;
  noOptionsText?: string;
  onChange: (
    value: TClearable extends true ? TValue | undefined : TValue,
    e: SyntheticEvent,
  ) => void;
  onClose?: (e: SyntheticEvent, reason: string) => void;
  onInputChange?: (e: SyntheticEvent, value: string, reason: string) => void;
  options: ReadonlyArray<
    U21SelectOptionProps<TValue> | U21SelectNestedMenuProps
  >;
  placeholder?: string;
  ref?: RefObject<HTMLDivElement>;
  required?: boolean;
  responsiveLength?: boolean;
  searchable?: boolean;
  showInvalidValue?: boolean;
  startIcon?: ReactNode;
  optionLimit?: number;
  value?: TValue;
}

const U21SelectInner = <
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
>(
  props: U21SelectProps<TValue, TClearable>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const {
    allowInvalidValues,
    autoFocus,
    backdrop,
    backdropProps,
    clearable = true,
    disabled,
    endIcon,
    error,
    expand,
    sortByMatchednessDisabled = false,
    filterOptions = createU21FilterOptions<TValue>,
    inputProps = {},
    inputValue,
    label,
    loading = false,
    noOptionsText,
    onChange,
    onClose,
    onInputChange,
    options,
    placeholder,
    required,
    responsiveLength,
    searchable = true,
    showInvalidValue = false,
    startIcon,
    optionLimit = 1000,
    value,
    ...rest
  } = props;
  const textFieldRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  const flattenedOptions: ReadonlyArray<
    U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps
  > = useMemo(
    () =>
      options.reduce<
        (U21FlattenedSelectOptionProps<TValue> | U21SelectNestedMenuProps)[]
      >((acc, i) => {
        if (!i.optionType) {
          flattenOption<TValue>(i).forEach((each) => acc.push(each));
        } else {
          acc.push(i);
        }
        return acc;
      }, []),
    [options],
  );

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      const valueSet = new Set();
      const duplicateValueSet = new Set();
      flattenedOptions.forEach((i) => {
        if (!i.optionType && i.value !== undefined) {
          if (valueSet.has(i.value)) {
            duplicateValueSet.add(i.value);
          }
          valueSet.add(i.value);
        }
      });
      if (duplicateValueSet.size) {
        throw new Error(
          `U21Select cannot have duplicate values: ${[
            ...duplicateValueSet,
          ].join(',')}`,
        );
      }
    }
  }, [flattenedOptions]);

  const fieldIdentifier =
    rest?.name ?? rest?.id ?? inputProps?.name ?? inputProps?.id ?? label;
  const actualValue =
    useMemo<U21FlattenedSelectOptionProps<TValue> | null>(() => {
      if (value === undefined) {
        return null;
      }

      const v = flattenedOptions
        .filter<
          U21FlattenedSelectOptionProps<TValue>
        >((i): i is U21FlattenedSelectOptionProps<TValue> => !i.optionType && i.value !== undefined)
        .find((i) => isEqual(i.value, value));

      if (v !== undefined) {
        return v;
      }

      if (fieldIdentifier && !allowInvalidValues) {
        consoleError(`Invalid value in U21Select field: ${fieldIdentifier}`);
      }
      return showInvalidValue
        ? { text: value, value, searchTerms: [value], values: [value] }
        : null;
    }, [
      allowInvalidValues,
      flattenedOptions,
      value,
      showInvalidValue,
      fieldIdentifier,
    ]);

  const hasClearIcon = clearable && !disabled && value !== undefined;

  // force focus since clicking on start + end adornments don't cause focus
  useEffect(() => {
    if (open) {
      inputRef?.current?.focus();
    }
  }, [open]);

  // calls onChange and tracks the analytics
  const onChangeAnalyticsWrapper = (
    option: U21FlattenedSelectOptionProps<TValue> | undefined,
    e: SyntheticEvent,
  ) => {
    trackEvent(AnalyticsEvents.U21SELECT_ON_CHANGE, props, {}, option);
    if (option?.value !== undefined) {
      onChange(option.value, e);
    } else if (clearable) {
      // @ts-ignore when TClearable is true, onChange value can be undefined but TS doesn't think so
      onChange(undefined, e);
    }
  };

  return (
    <>
      {backdrop &&
        createPortal(
          <StyledBackdrop invisible {...backdropProps} open={open} />,
          document.body,
        )}
      <Autocomplete
        autoHighlight
        blurOnSelect
        disableClearable={!clearable}
        disabled={disabled}
        filterOptions={(allOptions, state) => {
          const filteredOptions = filterOptions(allOptions, state);
          // filterOptions allows U21SelectOption to make it easier for devs to use
          // so turn U21SelectOption into U21FlattenedSelectOptionProps
          const flattened = filteredOptions.reduce<
            (U21SelectNestedMenuProps | U21FlattenedSelectOptionProps<TValue>)[]
          >((acc, i) => {
            // filter out U21SelectedNestedMenuProps and U21FlattenedSelectOptionProps
            if (i.optionType || 'values' in i) {
              acc.push(i);
            } else {
              flattenOption<TValue>(i).forEach((each) => acc.push(each));
            }
            return acc;
          }, []);
          if (sortByMatchednessDisabled) return flattened;
          const { inputValue: searchInputValue } = state;
          return sortByMatchedness(flattened, searchInputValue);
        }}
        fullWidth={!responsiveLength}
        getOptionLabel={(option: U21FlattenedSelectOptionProps<TValue>) =>
          String(option.text)
        }
        inputValue={inputValue}
        ListboxComponent={Menu}
        noOptionsText={noOptionsText}
        onChange={(e, option: U21FlattenedSelectOptionProps<TValue> | null) => {
          if (!option?.disabled) {
            onChangeAnalyticsWrapper(option ?? undefined, e);
          }
        }}
        onClose={(e, reason) => {
          onClose?.(e, reason);
          if (reason === 'blur' || reason === 'escape') {
            setOpen(false);
          }
        }}
        onOpen={() => setOpen(true)}
        onInputChange={(e, newSearchValue, reason) => {
          onInputChange?.(e, newSearchValue, reason);
          if (reason === 'input' && !open) {
            setOpen(true);
          }
        }}
        open={open}
        options={flattenedOptions}
        popupIcon={<IconChevronDown />}
        loading={loading}
        ref={ref}
        renderInput={(params) => {
          const {
            inputProps: inputInputProps,
            InputProps,
            size,
            ...restParams
          } = params;
          return (
            <AutocompleteTextField
              {...restParams}
              responsiveLength={responsiveLength}
              $color={actualValue?.color}
              autoFocus={autoFocus}
              error={error}
              InputLabelProps={{ required }}
              inputProps={{
                ...inputInputProps,
                ...inputProps,
                readOnly: !searchable,
              }}
              InputProps={{
                ...InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    {hasClearIcon && (
                      <ClearButton
                        onClick={(e) => {
                          e.stopPropagation();
                          onChangeAnalyticsWrapper(undefined, e);
                          // use setTimeout to allow onChange to happen first
                          setTimeout(() => inputRef.current?.focus(), 0);
                        }}
                      />
                    )}
                    <Loading loading={loading} />
                    {!disabled && (
                      <AdornmentButton
                        aria-label="Open"
                        disabled={disabled}
                        icon={open ? <IconChevronUp /> : <IconChevronDown />}
                        onClick={() => setOpen(!open)}
                      />
                    )}
                    {endIcon}
                  </InputAdornment>
                ),
              }}
              inputRef={inputRef}
              label={label}
              loading={loading}
              onChange={emptyFn}
              placeholder={placeholder}
              ref={textFieldRef}
              startIcon={startIcon}
            />
          );
        }}
        renderOption={(optionProps, option, state) => {
          if (optionLimit && state.index >= optionLimit) {
            return null;
          }

          // Use 'type' prop as a type guard to differentiate bt select options and menu items
          if (!option.optionType) {
            // adjust for select automatically using selected value for search
            const actualInputValue =
              state.inputValue === actualValue?.text ? '' : state.inputValue;

            // don't render nested options if search filter is empty
            if (option.isNestedOption && !actualInputValue) {
              return null;
            }

            // don't render non-leaf nodes if searching
            if (actualInputValue && option.children) {
              return null;
            }
            const {
              color,
              children,
              className,
              key,
              onClick,
              ...restOptionProps
            } = optionProps;
            return (
              <SelectOptionItem
                key={key}
                {...restOptionProps}
                {...createSelectOptionItemProps(
                  option,
                  (newOption: U21FlattenedSelectOptionProps<TValue>, e) => {
                    onChangeAnalyticsWrapper(newOption, e);
                    setOpen(false);
                  },
                  actualValue,
                )}
              />
            );
          }
          const { item, alignRight } = option;
          return (
            <U21MenuItem
              key={item.key || (item.text as string)}
              alignRight={alignRight}
              item={item}
              // disable menu focus because select menu will close when it loses focus
              menuAutoFocus={false}
              onClose={() => setOpen(false)}
            />
          );
        }}
        size="small"
        slotProps={{
          popper: {
            placement: 'bottom-start',
            style: generatePopperStyleProp({
              backdrop,
              expand,
              width: textFieldRef.current?.clientWidth,
            }),
          },
        }}
        value={actualValue}
        {...getDOMProps(rest)}
      />
    </>
  );
};

export const U21Select = forwardRef(U21SelectInner);

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ENTITY_QUERY_KEYS } from 'app/modules/entitiesRefresh/queries/keys';
import { UNIT21_ID_FIELD_NOT_FOUND } from 'app/modules/search/constants';
import { EntitySearchPayload } from 'app/modules/search/payload';
import {
  EntitySearchV2Response,
  EntitySource,
} from 'app/modules/search/response';
import { post } from 'app/shared/utils/fetchr';
import { unionBy } from 'lodash';
import { useMemo } from 'react';

const getEntitySources = (data: EntitySearchV2Response): EntitySource[] =>
  data.hits.map((hit) => hit.source);

export const searchEntity = (payload?: EntitySearchPayload) =>
  post<EntitySearchV2Response>('/entities/search/v2', payload);

export const useEntitySearch = (
  payload?: EntitySearchPayload,
  enabled?: boolean,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<EntitySearchV2Response, Error, EntitySource[]>({
    queryFn: () => searchEntity(payload),
    queryKey: ENTITY_QUERY_KEYS.getEntitySearch(payload),
    enabled: Boolean(enabled && !!payload),
    staleTime: 5 * 60 * 1000,
    select: (data) => getEntitySources(data),
  });

  const cachedQueryData = queryClient.getQueriesData<EntitySearchV2Response>({
    queryKey: ENTITY_QUERY_KEYS.getEntitySearch(),
  });

  const entities = useMemo(() => {
    const allEntities = cachedQueryData.reduce<EntitySource[]>((acc, i) => {
      const [, queryData] = i;
      if (queryData) {
        return unionBy(getEntitySources(queryData), acc, 'external_id');
      }
      return acc;
    }, []);

    return allEntities.filter((e) => e.unit21_id !== UNIT21_ID_FIELD_NOT_FOUND);
  }, [cachedQueryData]);

  return {
    ...query,
    entities,
  };
};

import { useEffect, useMemo, useRef, useState } from 'react';

import { createU21FilterOptions } from 'app/shared/u21-ui/components/input/select/utils';
import { debounce } from 'lodash';

import {
  U21MultiSelect,
  U21MultiSelectProps,
} from 'app/shared/u21-ui/components/input/select/U21MultiSelect';
import {
  U21Select,
  U21SelectOptionProps,
  U21SelectProps,
  U21SelectValue,
} from 'app/shared/u21-ui/components/input/select/U21Select';

interface U21SearchPropsBase {
  delay?: number;
  onSearch: (value?: string) => void;
}

export interface U21SelectSearchSingleProps<
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
> extends Omit<U21SelectProps<TValue, TClearable>, 'ref'>,
    U21SearchPropsBase {
  multi?: false;
}

export interface U21SelectSearchMultiProps<
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
> extends Omit<U21MultiSelectProps<TValue, TClearable>, 'ref'>,
    U21SearchPropsBase {
  multi: true;
}

export type U21SelectSearchProps<
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
> =
  | U21SelectSearchSingleProps<TValue, TClearable>
  | U21SelectSearchMultiProps<TValue, TClearable>;

export const U21SelectSearch = <
  TValue extends U21SelectValue,
  TClearable extends boolean = true,
>(
  props: U21SelectSearchProps<TValue, TClearable>,
) => {
  const {
    clearable,
    delay = 300,
    inputProps,
    loading,
    multi,
    onChange,
    onSearch,
    options,
    value,
    ...rest
  } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const valueText = useMemo(() => {
    const valueOption = options.find((i) => {
      if (i.optionType === undefined) {
        return i.value === value;
      }
      return false;
    });
    if (valueOption) {
      // safe to cast here bc if valueOption is truthy from above it must be of type U21SelectOptionProps
      return String((valueOption as U21SelectOptionProps<TValue>).text);
    }
    return '';
  }, [options, value]);

  // used instead of loading due to debounce
  const [internalLoading, setInternalLoading] = useState(loading);

  const onSearchDebounced = useMemo(
    () => debounce(onSearch, delay),
    [delay, onSearch],
  );
  const onSearchDebouncedRef = useRef(onSearchDebounced);
  onSearchDebouncedRef.current = onSearchDebounced;

  useEffect(() => {
    onSearchDebouncedRef.current(undefined);
  }, []);

  useEffect(() => {
    if (!loading) {
      setInternalLoading(false);
    }
  }, [loading, options]);

  const commonProps:
    | Omit<U21SelectProps<TValue, TClearable>, 'onChange' | 'options' | 'value'>
    | Omit<
        U21MultiSelectProps<TValue, TClearable>,
        'onChange' | 'options' | 'value'
      > = {
    filterOptions: (selectOptions, state) => {
      if (!searchValue || internalLoading) {
        return selectOptions;
      }
      return createU21FilterOptions<TValue>(selectOptions, state);
    },
    inputProps: {
      delay: 0,
      ...inputProps,
      onKeyDown: (e) => {
        inputProps?.onKeyDown?.(e);
        const { key } = e;
        // disable removing selected behavior
        if (key === 'Backspace' || key === 'Delete') {
          e.stopPropagation();
        }
        if (key === 'Enter') {
          onSearchDebounced(searchValue);
        }
      },
    },
    loading: internalLoading,
    onInputChange: (e, newSearchValue, reason) => {
      if (reason === 'input') {
        setSearchValue(newSearchValue);
        if (newSearchValue) {
          onSearchDebounced(newSearchValue);
          setInternalLoading(true);
        }
      } else if (reason === 'clear') {
        setSearchValue('');
      }
    },
  };

  if (multi) {
    return (
      <U21MultiSelect
        {...rest}
        {...commonProps}
        clearable={clearable}
        inputValue={searchValue}
        // for some reason generics aren't working in this component so manually define TValue
        onChange={(newValue: TValue[], e) => {
          setSearchValue('');
          onChange(newValue, e);
        }}
        options={options}
        value={value}
      />
    );
  }
  return (
    <U21Select
      allowInvalidValues
      {...rest}
      {...commonProps}
      clearable={clearable}
      // searchValue takes precedence over valueText since it will contain it
      inputValue={searchValue || valueText}
      onChange={(
        // for some reason generics aren't working in this component so manually define TValue
        newValue: TClearable extends true ? TValue | undefined : TValue,
        e,
      ) => {
        setSearchValue('');
        onChange(newValue, e);
      }}
      options={options}
      value={value}
    />
  );
};

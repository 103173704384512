import { SearchRequest } from 'app/modules/search/models';

const BASE = ['search'] as const;

export const SEARCH_QUERY_KEYS = {
  searchInstrumentsV1: (payload?: SearchRequest) => {
    if (payload) {
      return [...BASE, 'search', payload] as const;
    }
    return [...BASE, 'search'];
  },
};

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { unionBy } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { post } from 'app/shared/utils/fetchr';
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';
import {
  InstrumentSearchV2Response,
  InstrumentSource,
} from 'app/modules/search/response';
import { InstrumentSearchPayload } from 'app/modules/search/payload';
import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';

const getInstrumentSources = (
  data: InstrumentSearchV2Response,
): InstrumentSource[] => data.hits.map((hit) => hit.source);

export const searchInstruments = async (payload?: InstrumentSearchPayload) => {
  return post<InstrumentSearchV2Response>('/instruments/search/v2', payload);
};

export const useInstrumentSearch = (
  payload?: InstrumentSearchPayload,
  enabled?: boolean,
) => {
  const queryClient = useQueryClient();
  const hasReadInstrumentsPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );

  const query = useQuery<InstrumentSearchV2Response, Error, InstrumentSource[]>(
    {
      enabled: Boolean(
        enabled && payload && payload.limit && hasReadInstrumentsPermissions,
      ),
      queryFn: () => searchInstruments(payload),
      queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentSearch(payload),
      staleTime: 5 * 60 * 1000,
      select: (data) => getInstrumentSources(data),
    },
  );

  const cachedQueryData =
    queryClient.getQueriesData<InstrumentSearchV2Response>({
      queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentSearch(),
    });

  const instruments = useMemo(
    () =>
      cachedQueryData.reduce<InstrumentSource[]>((acc, i) => {
        const [, queryData] = i;
        if (queryData) {
          return unionBy(getInstrumentSources(queryData), acc, 'external_id');
        }
        return acc;
      }, []),
    [cachedQueryData],
  );

  return {
    ...query,
    instruments,
  };
};

import {
  GetInstrumentsPayload,
  RetrieveInstrumentTxnsPayload,
  PaginatedSilencedRulesPayload,
} from 'app/modules/instruments/requests';
import { InstrumentSearchPayload } from 'app/modules/search/payload';
import { PaginationPayload } from 'app/shared/pagination/models';

const BASE = ['instruments'];

export const INSTRUMENT_QUERY_KEYS = {
  getInstruments: (payload: GetInstrumentsPayload) =>
    [...BASE, payload] as const,
  getInstrumentDetails: (instrumentId?: string | number) => {
    if (instrumentId === undefined) {
      return [...BASE, 'instrument-details'] as const;
    }
    return [...BASE, 'instrument-details', instrumentId] as const;
  },
  getTransactionSummary: (instrumentId: string) =>
    [...BASE, 'transaction-summary', instrumentId] as const,
  getTransactions: (
    args: RetrieveInstrumentTxnsPayload & { instrumentId: string },
  ) => [...BASE, 'transactions', args] as const,
  getPaginatedSilencedRules: (payload?: PaginatedSilencedRulesPayload) => {
    if (payload === undefined) {
      return [...BASE, 'silenced-rules'] as const;
    }
    return [...BASE, 'silenced-rules', payload] as const;
  },
  getInstrumentAlerts: (
    args: Partial<PaginationPayload & { instrumentExternalId: string }>,
  ) => [...BASE, 'instrument-alerts', args] as const,
  getInstrumentConsortiumHits: (instrumentId: string) =>
    [...BASE, 'consortium-hits', instrumentId] as const,
  getInstrumentNotes: (instrumentExternalId: string) =>
    [...BASE, 'instrument-notes', instrumentExternalId] as const,
  getInstrumentSearch: (payload?: InstrumentSearchPayload) =>
    payload
      ? ([...BASE, 'search', payload] as const)
      : ([...BASE, 'search'] as const),
};

import { useQuery, useQueryClient } from '@tanstack/react-query';
import { TransactionSearchPayload } from 'app/modules/search/payload';
import {
  TransactionSearchV2Response,
  TransactionSource,
} from 'app/modules/search/response';
import { TRANSACTION_QUERY_KEYS } from 'app/modules/transactions/queries/keys';
import { post } from 'app/shared/utils/fetchr';
import { unionBy } from 'lodash';
import { useMemo } from 'react';

const getTransactionSources = (
  data: TransactionSearchV2Response,
): TransactionSource[] => data.hits.map((hit) => hit.source);

export const searchTransaction = (payload?: TransactionSearchPayload) =>
  post<TransactionSearchV2Response>('/txn_events/search/v2', payload);

export const useTransactionSearch = (
  payload?: TransactionSearchPayload,
  enabled?: boolean,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<
    TransactionSearchV2Response,
    Error,
    TransactionSource[]
  >({
    queryFn: () => searchTransaction(payload),
    queryKey: TRANSACTION_QUERY_KEYS.getTransactionSearch(payload),
    enabled: Boolean(enabled && !!payload),
    staleTime: 5 * 60 * 1000,
    select: (data) => getTransactionSources(data),
  });

  const cachedQueryData =
    queryClient.getQueriesData<TransactionSearchV2Response>({
      queryKey: TRANSACTION_QUERY_KEYS.getTransactionSearch(),
    });

  const transactions = useMemo(
    () =>
      cachedQueryData.reduce<TransactionSource[]>((acc, i) => {
        const [, queryData] = i;
        if (queryData) {
          return unionBy(getTransactionSources(queryData), acc, 'external_id');
        }
        return acc;
      }, []),

    [cachedQueryData],
  );

  return {
    ...query,
    transactions,
  };
};

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Thunks
import { retrieveNarrativeTemplatesSearchThunk } from 'app/modules/narrativeTemplates/sliceNarrativeTemplates';

// Components
import { IconClipboardList } from '@u21/tabler-icons';
import {
  U21Select,
  U21SelectOptionProps,
  U21SelectProps,
} from 'app/shared/u21-ui/components';

// Models
import { NarrativeTemplateType } from 'app/modules/narrativeTemplates/models';

// Selectors
import { selectFormattedNarrativeTemplates } from 'app/modules/narrativeTemplates/selectors';

interface U21NarrativeTemplateSelectProps<TClearable extends boolean>
  extends Omit<U21SelectProps<number, TClearable>, 'options'> {
  queueID: number | null;
  type: NarrativeTemplateType;
}

export const U21NarrativeTemplateSelect = <TClearable extends boolean>(
  props: U21NarrativeTemplateSelectProps<TClearable>,
) => {
  const { queueID, type, ...rest } = props;
  const dispatch = useDispatch();
  const options = useSelector(
    selectFormattedNarrativeTemplates(type),
  ) as U21SelectOptionProps[];

  useEffect(() => {
    dispatch(
      retrieveNarrativeTemplatesSearchThunk({
        queue_id: queueID,
        type,
      }),
    );
  }, [queueID, dispatch, type]);

  const selectProps = {
    label: 'Narrative Templates',
    options,
    startIcon: <IconClipboardList />,
  };

  return <U21Select allowInvalidValues {...selectProps} {...rest} />;
};

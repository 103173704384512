import { useSelector } from 'react-redux';
import {
  FC,
  HTMLProps,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

// Components
import {
  U21SelectSearch,
  U21SelectSearchProps,
  U21SelectOptionProps,
} from 'app/shared/u21-ui/components';
import { IconCreditCard } from '@u21/tabler-icons';

// Selectors
import { selectHasReadInstrumentsPermission } from 'app/modules/session/selectors';

// Constants
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

// Helpers
import { formatInstrumentOptions } from 'app/modules/search/helpers';
import { uniqBy } from 'lodash';
import { InstrumentSearchRequest } from 'app/modules/search/models';
import { useQueryClient } from '@tanstack/react-query';
import {
  searchInstrumentsV1,
  useSearchInstrumentsV1,
} from 'app/modules/search/queries/useSearchInstruments';
import { SEARCH_QUERY_KEYS } from 'app/modules/search/queries/keys';
import { InstrumentSearchOptions } from 'app/modules/search/enum';
import {
  searchInstruments,
  useInstrumentSearch,
} from 'app/modules/instruments/queries/useInstrumentSearch';
import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import { INSTRUMENT_QUERY_KEYS } from 'app/modules/instruments/queries/keys';

export interface U21InstrumentSearchProps
  extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'onChange' | 'value'> {
  disabled?: boolean;
  error?: boolean;
  clearable?: boolean;
  onChange: (value: string[], e: SyntheticEvent) => void;
  value?: string[];
  searchProps?: Omit<
    U21SelectSearchProps<number>,
    | 'disabled'
    | 'error'
    | 'clearable'
    | 'onChange'
    | 'onSearch'
    | 'options'
    | 'ref'
    | 'value'
    | 'multi'
    | 'as'
  >;
  additionalOptions?: U21SelectOptionProps[];
}

export const U21InstrumentSearch: FC<U21InstrumentSearchProps> = ({
  disabled,
  error,
  clearable = true,
  onChange,
  value = [],
  searchProps = {},
  additionalOptions = [],
  ...rest
}) => {
  const queryClient = useQueryClient();

  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);
  const hasReadInstrumentsPermissions = useSelector(
    selectHasReadInstrumentsPermission,
  );
  const [search, setSearch] = useState<string | undefined>('');

  const isV2SearchUsed = useMemo(
    () => Boolean(isSearchV2Enabled),
    [isSearchV2Enabled],
  );

  const { instruments: instrumentV1, isFetching: isFetchingV1 } =
    useSearchInstrumentsV1(
      {
        limit: INFINITE_SCROLL_LIMIT,
        offset: OFFSET_DEFAULT,
        phrase: search || '',
        type: InstrumentSearchOptions.EXTERNAL_ID,
      },
      !isV2SearchUsed,
    );

  const { instruments: instrumentV2, isFetching: isFetchingV2 } =
    useInstrumentSearch(
      {
        search_field: InstrumentSearchOptions.EXTERNAL_ID,
        phrases: search ? [search] : [],
        exact_match: true,
        offset: OFFSET_DEFAULT,
        limit: 1,
        all_phrases_must_match: false,
      },
      isV2SearchUsed,
    );

  const instruments = useMemo(
    () => (isV2SearchUsed ? instrumentV2 : instrumentV1),
    [instrumentV1, instrumentV2, isV2SearchUsed],
  );

  const isFetching = useMemo(
    () => (isV2SearchUsed ? isFetchingV2 : isFetchingV1),
    [isFetchingV1, isFetchingV2, isV2SearchUsed],
  );

  const options = useMemo(() => {
    return uniqBy(
      [...formatInstrumentOptions(instruments), ...additionalOptions],
      'value',
    );
  }, [additionalOptions, instruments]);

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadInstrumentsPermissions) {
      const missingValues = value.filter(
        (i) => !options.find((j) => j.value === i),
      );
      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;

        if (isV2SearchUsed) {
          const payload = {
            search_field: InstrumentSearchOptions.EXTERNAL_ID,
            phrases: missingValues.map(String),
            exact_match: true,
            offset: OFFSET_DEFAULT,
            limit: missingValues.length,
            all_phrases_must_match: false,
          };

          queryClient.prefetchQuery({
            queryKey: INSTRUMENT_QUERY_KEYS.getInstrumentSearch(payload),
            queryFn: () => searchInstruments(payload),
          });
        } else {
          const searchPayload: InstrumentSearchRequest = {
            phrase: '',
            limit: missingValues.length,
            offset: OFFSET_DEFAULT,
            type: 'external_id',
            external_ids: missingValues,
          };

          queryClient.prefetchQuery({
            queryKey: SEARCH_QUERY_KEYS.searchInstrumentsV1(searchPayload),
            queryFn: () => searchInstrumentsV1(searchPayload),
          });
        }
      }
    }
  }, [
    hasReadInstrumentsPermissions,
    isV2SearchUsed,
    options,
    queryClient,
    value,
  ]);

  if (!hasReadInstrumentsPermissions) {
    return null;
  }

  return (
    <StyledU21SelectSearch
      disabled={disabled}
      error={error}
      clearable={clearable}
      limitTags={1}
      loading={isFetching}
      multi
      onChange={onChange}
      options={options}
      onSearch={setSearch}
      placeholder="Any"
      startIcon={<IconCreditCard />}
      value={value}
      {...rest}
      {...searchProps}
    />
  );
};

const StyledU21SelectSearch = styled(U21SelectSearch)`
  min-width: 300px;
`;

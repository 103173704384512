import { useMemo } from 'react';

import { IconClipboardList } from '@u21/tabler-icons';
import {
  U21MultiSelect,
  U21MultiSelectProps,
  U21Select,
  U21SelectOptionProps,
  U21SelectProps,
} from 'app/shared/u21-ui/components';

import { QueueType } from 'app/modules/queues/models';
import { QueueSubtypes } from 'app/modules/alerts/models';

import { QUEUE_TYPE_LABEL } from 'app/modules/queues/constants';

import { useGetAllQueues } from 'app/modules/queues/queries/useGetAllQueues';
import { convertFormattedQueues } from 'app/modules/queues/helpers';
import { useSelector } from 'react-redux';
import { selectHasReadQueuePermission } from 'app/modules/queues/selectors';

interface U21QueueSelectSingleProps<TClearable extends boolean>
  extends Omit<U21SelectProps<number, TClearable>, 'options'> {
  multi?: false;
  queueType: QueueType;
  queueSubtype?: QueueSubtypes;
}

interface U21QueueSelectMultiProps<TClearable extends boolean>
  extends Omit<U21MultiSelectProps<number, TClearable>, 'options'> {
  multi: true;
  queueType: QueueType;
  queueSubtype?: QueueSubtypes;
}

export type U21QueueSelectProps<TClearable extends boolean = true> =
  | U21QueueSelectSingleProps<TClearable>
  | U21QueueSelectMultiProps<TClearable>;

export const U21QueueSelect = <TClearable extends boolean = true>(
  props: U21QueueSelectProps<TClearable>,
) => {
  const { onChange, multi, queueType, queueSubtype, value, ...rest } = props;

  const { data } = useGetAllQueues(queueType);

  const hasReadQueuePermission = useSelector((state: RootState) =>
    selectHasReadQueuePermission(state, queueType),
  );

  const options = useMemo<U21SelectOptionProps[]>(
    () => convertFormattedQueues(data?.queues, queueType, queueSubtype),
    [data?.queues, queueSubtype, queueType],
  );

  if (!hasReadQueuePermission) {
    return null;
  }

  const selectProps = {
    label: QUEUE_TYPE_LABEL[queueType],
    options,
    startIcon: <IconClipboardList />,
  };

  if (multi) {
    return (
      <U21MultiSelect
        {...selectProps}
        {...rest}
        onChange={onChange}
        value={value}
      />
    );
  }
  return (
    <U21Select
      allowInvalidValues
      {...selectProps}
      {...rest}
      onChange={onChange}
      value={value}
    />
  );
};
